import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VehicleCard from "./VehicleCard";
import Search from "../../Pages/VehiclePage/Search"; // Import the Search component
import { SwiperSlide } from "swiper/react";
import CallToAction from "./CallToAction";

function VehicleList({ featuredVehicles, vehicles }) {
  // Pagination states
  const [currentFeaturedPage, setCurrentFeaturedPage] = useState(1);
  const [currentVehiclesPage, setCurrentVehiclesPage] = useState(1);

  // Search filter state
  const [filters, setFilters] = useState({
    make: "",
    model: "",
    type: "",
    year: "",
    condition: "",
    priceFrom: "",
    priceTo: "",
  });

  const [filteredVehicles, setFilteredVehicles] = useState(vehicles);

  const featuredItemsPerPage = 3;
  const vehiclesItemsPerPage = 9;

  // Filter logic
  useEffect(() => {
    const filterVehicles = () => {
      let filtered = vehicles;

      if (filters.make) {
        filtered = filtered.filter((item) =>
          item.make.toLowerCase().includes(filters.make.toLowerCase())
        );
      }
      if (filters.model) {
        filtered = filtered.filter((item) =>
          item.model.toLowerCase().includes(filters.model.toLowerCase())
        );
      }
      if (filters.year) {
        filtered = filtered.filter(
          (item) => item.year === parseInt(filters.year)
        );
      }
      if (filters.condition) {
        filtered = filtered.filter((item) =>
          item.condition.toLowerCase().includes(filters.condition.toLowerCase())
        );
      }
      if (filters.type) {
        filtered = filtered.filter((item) =>
          item.type.toLowerCase().includes(filters.type.toLowerCase())
        );
      }
      if (filters.priceFrom) {
        filtered = filtered.filter(
          (item) => item.price >= parseFloat(filters.priceFrom)
        );
      }
      if (filters.priceTo) {
        filtered = filtered.filter(
          (item) => item.price <= parseFloat(filters.priceTo)
        );
      }

      setFilteredVehicles(filtered);
    };

    filterVehicles();
  }, [filters, vehicles]);

  // Event handler for Search
  const handleSearch = (newFilters) => {
    setFilters(newFilters);
    console.log(filters);
  };

  // Calculate pagination for filtered vehicles
  const vehiclesIndexOfLastItem = currentVehiclesPage * vehiclesItemsPerPage;
  const vehiclesIndexOfFirstItem =
    vehiclesIndexOfLastItem - vehiclesItemsPerPage;
  const currentVehiclesItems = filteredVehicles?.slice(
    vehiclesIndexOfFirstItem,
    vehiclesIndexOfLastItem
  );
  const totalVehiclesPages = Math.ceil(
    filteredVehicles?.length / vehiclesItemsPerPage
  );

  return (
    <>
      {/* Search Component */}
      <Search onSearch={handleSearch} />
      <CallToAction />
      {/* Featured Vehicles */}
      <div className="max-w-[1400px] mx-auto my-16 px-4">
        <h4 className="font-bold text-3xl my-4">Featured</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-4">
          {featuredVehicles?.slice(0, 3).map((item) => (
            <Link
              key={item._id}
              to={`/cars/${item._id}`}
              onClick={() => localStorage.setItem("carId", item._id)}
            >
              <VehicleCard item={item} featured={true} />
            </Link>
          ))}
        </div>
      </div>

      {/* Browse Vehicles */}
      <div className="max-w-[1400px] mx-auto my-16 px-4">
        <h4 className="font-bold text-3xl my-4">Browse</h4>
        <div className="grid gap-x-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-8">
          {currentVehiclesItems?.map((item) => (
            <SwiperSlide key={item._id}>
              <Link
                to={`/cars/${item._id}`}
                onClick={() => localStorage.setItem("carId", item._id)}
              >
                <VehicleCard item={item} featured={false} />
              </Link>
            </SwiperSlide>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mx-2 bg-gray-300 rounded"
            disabled={currentVehiclesPage === 1}
            onClick={() => setCurrentVehiclesPage(currentVehiclesPage - 1)}
          >
            Previous
          </button>
          <span className="content-center">
            Page {currentVehiclesPage} of {totalVehiclesPages}
          </span>
          <button
            className="px-4 py-2 mx-2 bg-gray-300 rounded"
            disabled={currentVehiclesPage === totalVehiclesPages}
            onClick={() => setCurrentVehiclesPage(currentVehiclesPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default VehicleList;
