import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import styles from "./VehiclePage.module.css";
import Search from "./Search";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import SliderImg1 from "../../Images/sliderImg1.png";
import SliderImg2 from "../../Images/sliderImg2.png";
import SliderImg3 from "../../Images/sliderImg3.png";
import Sponsored from "../../Components/Common/Sponsored";
import Bulldozer from "../../Components/Common/Bulldozer";
import CallToAction from "../../Components/Common/CallToAction";
import VehicleList from "../../Components/Common/VehicleList";
import DealersList from "../../Components/Common/DealersList";
import { Audio } from "react-loader-spinner";
import { IoConstructOutline } from "react-icons/io5";
import Feedback from "../../Components/Common/Feedback";

const BikePage = ({ bg, dealers }) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false, // Hides the arrows
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 0,
    arrows: false, // Hides the arrows
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [vehicles, setVehicles] = useState([]);
  const [featuredVehicles, setFeaturedVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    console.log("length", vehicles?.length);
    console.log("working");

    fetchAllVehicles();
    fetchFeaturedVehicles();

    console.log("useEffecct", vehicles, featuredVehicles);
  }, []);

  console.log("normal", vehicles, featuredVehicles);

  const fetchAllVehicles = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-bike-listings`
      );
      setVehicles(response?.data?.listings);
      console.log("lalalal", response?.data?.listings);
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      console.log("finally", vehicles);
      setLoading(false);
    }
  };

  const fetchFeaturedVehicles = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-featured-bike-listings`
      );
      setFeaturedVehicles(response?.data?.data);
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      console.log("featured", featuredVehicles);
      setLoading(false);
    }
  };
  return loading ? (
    <div className="flex items-center h-screen justify-center">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="rgb(185, 142, 53)"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : (
    <div>
      <div className={styles.carsMain}>
        <div className={`${bg} bg-center bg-cover bg-no-repeat -mt-48`}>
          <div className={styles.carsMainHeading}>
            <div className={`${styles.carsHeading}`}>
              ZIMBABWE'S PREMIER VEHICLE MARKETPLACE <br /> Revolutionizing How
              Zimbabwe Buys and Sells Vehicles
            </div>
            <div className={styles.carsText}>
              <ul className="mt-3 mb-8 space-y-2">
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;Launching a new era of digital automotive trading
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;AI-powered matching for better deals
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;Secure, transparent, and professional platform
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>&nbsp;Simple
                  24/7 Digital Showcase
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Vehicle List will generate the list of cars or boats or trucks based on data provided as prop */}
        {
          <VehicleList
            vehicles={vehicles}
            featuredVehicles={featuredVehicles}
          />
        }

        {/* dealers list */}
        {/* {dealers && <DealersList dealers={dealers} />} */}

        {/* Bulldozer component  */}
        <Bulldozer />

        {/* Sponsored Partners component  */}
        <Sponsored />

        {/* Industry component  */}
        <div
          className={styles.feedbackSliderMain}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Slider {...settings2}>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* Feedback  */}
        <Feedback />
      </div>
    </div>
  );
};

export default BikePage;
