import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DashboardAddListing({ vehicles }) {
  console.log(vehicles,'the vahicles here')
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear(); // Get current year
  const startYear = 1950; // Start year
  const years = [];

  // Generate an array of years from 1950 to the current year
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  useEffect(() => {
    console.log(vehicles?.length);
  }, []);

  const [formData, setFormData] = useState({
    make: "",
    model: "",
    year: "",
    fuel: "",
    mileage: "",
    transmission: "",
    category: "",
    condition: "",
    price: "",
    description: "",
    pictures: [],
    registration: [],
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevData) => {
      if (files) {
        return {
          ...prevData,
          [name]: [...prevData[name], ...files], // Append new files to the existing array
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");

    if (!token) {
      alert("No token found. Please login.");
      navigate("/login");
      setLoading(false);
      return;
    }

    if (vehicles?.length >= 2 && vehicles[0]?.user.category == "individual") {
      alert("You cannot upload more than 2 free listings");
      setLoading(false);
      return;
    }

    if (
      vehicles?.length >= 25 &&
      vehicles[0]?.user.subscriptionType == "Starter Showcase"
    ) {
      alert("You cannot upload more than 25 free listings");
      setLoading(false);
      return;
    }

    if (
      vehicles?.length >= 25 &&
      vehicles[0]?.user.subscriptionType == "Growth Accelerator"
    ) {
      alert("You cannot upload more than 75 free listings");
      setLoading(false);
      return;
    }

    // Prepare form data for the API
    const form = new FormData();

    for (const key in formData) {
      if (key === "pictures" || key === "registration") {
        // Append multiple files
        formData[key].forEach((file) => {
          form.append(key, file);
        });
      } else {
        form.append(key, formData[key]);
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/listing/add-listing`,
        {
          method: "POST",
          headers: {
            "x-auth-token": token, // Add token to the header
          },
          body: form,
        }
      );

      if (response.ok) {
        alert("Listing added successfully!");
        window.location.reload(); // Reload the page on success
        setLoading(false);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
        console.log(errorData);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error adding listing:", error);
      alert("An error occurred. Please try again.", error);
      setLoading(false);
    }
  };

  return (
    <div className="md:max-w-[1200px]">
      <h2 className="font-semibold text-2xl mb-4">List Your Vehicle</h2>
      <form
        onSubmit={handleSubmit}
        className="md:grid grid-cols-1 gap-4 md:grid-cols-2"
      >
        <div className="my-2 w-full">
          <input
            type="text"
            id="make"
            name="make"
            required
            placeholder="Make"
            value={formData.make}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2 col-span-2"
          />
        </div>
        <div className="my-2">
          <input
            type="text"
            name="model"
            id="model"
            required
            placeholder="Model"
            value={formData.model}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          />
        </div>
        <div className="my-2">
          <select
            name="year"
            id="year"
            required
            value={formData.year}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="my-2">
          <select
            name="fuel"
            id="fuel"
            required
            value={formData.fuel}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          >
            <option value="">Select Fuel Type</option>

            <option key="Petrol" value="Petrol">
              Petrol
            </option>
            <option key="Diesel" value="Diesel">
              Diesel
            </option>
            <option key="Electric" value="Electric">
              Electric
            </option>
          </select>
        </div>
        <div className="my-2">
          <select
            name="transmission"
            id="transmission"
            required
            value={formData.transmission}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          >
            <option value="">Select Transmission Type</option>

            <option key="Manual" value="Manual">
              Manual
            </option>
            <option key="Automatic" value="Automatic">
              Automatic
            </option>
          </select>
        </div>
        <div className="my-2 w-full">
          <select
            name="category"
            id="category"
            required
            value={formData.category}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          >
            <option value="">Category</option>
            <option value="Cars">Cars</option>
            <option value="Boats">Boats</option>
            <option value="Bikes">Bikes</option>
            <option value="Trucks">Trucks</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Earth Moving">Earth Moving</option>
            <option value="New">New</option>
            <option value="Used">Used</option>
            <option value="Car Rentals">Car Rentals</option>
          </select>
        </div>
        <div className="my-2">
          <input
            type="text"
            name="condition"
            id="condition"
            required
            placeholder="Condition"
            value={formData.condition}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          />
        </div>
        <div className="my-2">
          <input
            type="text"
            name="location"
            id="location"
            required
            placeholder="Location"
            value={formData.location}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          />
        </div>
        <div className="my-2">
          <input
            type="text"
            id="price"
            name="price"
            required
            placeholder="Price"
            value={formData.price}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          />
        </div>
        <div className="my-2">
          <input
            type="text"
            id="mileage"
            name="mileage"
            required
            placeholder="Mileage (10000km)"
            value={formData.mileage}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-sm p-2"
          />
        </div>
        <div className="my-2">
          <textarea
            id="description"
            name="description"
            rows="5"
            value={formData.description}
            onChange={handleChange}
            className="border w-full border-solid border-zinc-400 rounded-md p-2 col-span-2"
            placeholder="Type here about vehicle..."
          />
        </div>
        <div className="flex items-center gap-4">
          <label htmlFor="pictures" className="font-semibold pl-1">
            Upload Pictures
          </label>
          <input
            type="file"
            id="pictures"
            name="pictures"
            multiple
            onChange={(event) => {
              const files = event.target.files;
              if (files && files.length > 10) {
                alert("You can upload a maximum of 10 files only.");
                event.target.value = ""; // Reset the file input
              } else {
                console.log("Selected files:", files);
              }
              handleChange(event);
            }}
          />
        </div>
        <div className="flex items-center gap-4">
          <label htmlFor="registration" className="font-semibold pl-1">
            Upload Spec Sheet(s)
          </label>
          <input
            type="file"
            id="registration"
            name="registration"
            multiple
            accept="application/pdf"
            onChange={(event) => {
              const files = event.target.files;
              if (files && files.length > 10) {
                alert("You can upload a maximum of 10 files only.");
                event.target.value = ""; // Reset the file input
              } else {
                console.log("Selected files:", files);
              }
              handleChange(event);
            }}
          />
        </div>
        <button
          disabled={loading}
          type="submit"
          className={`text-white text-center ${
            !loading ? "bg-[#E7B04C]" : "bg-gray-700"
          } font-semibold py-2 px-8 self-center justify-self-center col-span-2 rounded-md text-lg mt-12`}
        >
          {loading ? "...loading" : "Submit"}
        </button>
      </form>
    </div>
  );
}

export default DashboardAddListing;
