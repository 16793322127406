function DealersCard({ item }) {
  console.log(item, "....");
  return (
    <div className="xl:max-w-96 shadow-xl shadow-zinc-100 rounded-3xl">
      <figure className="relative flex flex-col items-center justify-center py-4">
        {item?.logo !== null ? (
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${item?.logo[0]}`}
            alt=""
            className="h-80 w-full object-center object-cover rounded-t-2xl"
          />
        ) : (
          <img
            src="https://www.lhrla.com.pk/wp-content/uploads/2019/03/dummy.jpg"
            alt=""
            className="h-80 w-full object-center object-cover rounded-t-2xl"
          />
        )}
      </figure>
      <div className="p-4 mb-4 space-y-1 sm:space-y-2">
        <p className="font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl h-[72px]">
          {item.dealershipName}
        </p>
        <div className="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 512 512"
          >
            <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
          </svg>
          <p className="text-xs sm:text-base font-semibold">{item.contact}</p>
        </div>
        <div className="items-center justify-between">
          <p className="text-sm">{item.email}</p>
          <p className="mt-2 text-xs sm:text-sm text-zinc-700">
            {item.location}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DealersCard;
