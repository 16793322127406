import React from "react";
import styles from "./Header.module.css";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../Images/Approved logo 2.png";

const Header = ({ dark }) => {
  return (
    <div className={dark ? "bg-black" : ""}>
      <>
        {["lg"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className="bg-body-tertiary mb-3 navbar-dark"
          >
            <Container fluid>
              <Navbar.Brand href="/">
                <img src={Logo} alt="" className="w-24" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img src={Logo} className="w-24" alt="logo" />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="/">Cars</Nav.Link>
                    <Nav.Link href="/boats">Boats</Nav.Link>
                    <Nav.Link href="/bikes">Bikes</Nav.Link>
                    <Nav.Link href="/trucks">Trucks</Nav.Link>
                    <Nav.Link href="/agriculture">Agriculture</Nav.Link>
                    <Nav.Link href="/earth-moving">Earth Moving</Nav.Link>
                    <Nav.Link href="/new">New</Nav.Link>
                    <Nav.Link href="/used">Used</Nav.Link>
                    <Nav.Link href="/car-rentals">Car Rentals</Nav.Link>
                    <Nav.Link href="/dealers">Dealers</Nav.Link>
                  </Nav>
                  {!localStorage.token ? (
                    <>
                      <Nav.Link href="/signup">
                        <button className={styles.navvBtn}>
                          Sell Your Vehicle
                        </button>
                      </Nav.Link>
                      <Nav.Link href="/login" className="self-center">
                        <button className="ml-2 font-bold rounded-md border-2 py-2 px-6 border-[#B98E35] text-[#B98E35]">
                          Login
                        </button>
                      </Nav.Link>
                    </>
                  ) : (
                    <>
                      {JSON.parse(localStorage?.user).user.role === "buyer" && (
                        <Nav.Link href="/" className="self-center">
                          <button
                            onClick={() => {
                              localStorage.removeItem("token");
                              localStorage.removeItem("user");
                            }}
                            className="ml-2 font-bold rounded-md border-2 py-2 px-6 border-[#B98E35] text-[#B98E35]"
                          >
                            Logout
                          </button>
                        </Nav.Link>
                      )}
                      {(JSON.parse(localStorage?.user).user.role ===
                        "dealership" ||
                        JSON.parse(localStorage?.user).user.role ===
                          "individual") && (
                        <Nav.Link href="/" className="self-center">
                          <button
                            onClick={() => {
                              localStorage.removeItem("token");
                              localStorage.removeItem("user");
                            }}
                            className="ml-2 font-bold rounded-md border-2 py-2 px-6 border-[#B98E35] text-[#B98E35]"
                          >
                            Logout
                          </button>
                        </Nav.Link>
                      )}
                    </>
                  )}
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
    </div>
  );
};

export default Header;
