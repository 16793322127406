import React, { useState } from "react";
import Logo from "../../Images/Approved logo 2.png";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import TeleIcn from "../../Images/telegram.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState(""); // State to store email
  const [message, setMessage] = useState(""); // State to store feedback message

  const handleSubscribe = async () => {
    if (!email) {
      setMessage("Please enter a valid email.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/subscribe-newsletter`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (data.success) {
        alert("You have successfully subscribed!");
        setMessage("You have successfully subscribed!");
        setEmail(""); // Clear the input field
      } else {
        console.log(data);
        alert(data.message || "Something went wrong.");
        setMessage(data.message || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error subscribing to the newsletter:", error);
      setMessage("Failed to subscribe. Please try again later.");
    }
  };
  return (
    <div className="p-16 bg-black text-white">
      <div className="flex justify-between flex-wrap gap-4">
        <div className="flex flex-col gap-2 items-start">
          <img src={Logo} className="w-24" alt="" />
          {/* <div>+1 999 888-76-54</div> */}
          <div>sales@buysellcars.co.zw</div>
          <div className="flex gap-x-6">
            <Link
              to="https://www.facebook.com/share/15bRbCq6tF/?mibextid=LQQJ4d"
              target="_blank"
            >
              <FaFacebook className="text-2xl" />
            </Link>
            <Link
              to="https://www.instagram.com/buysellcars.zw/profilecard/?igsh=dWdpbDh1d2R4aGNm"
              target="_blank"
            >
              <FaInstagram className="text-2xl" />
            </Link>
          </div>
        </div>
        <div className="flex-col flex text-zinc-300">
          <div className="font-semibold text-xl mb-2 text-white">Category</div>
          <Link to="/">Cars</Link>
          <Link to="/bikes">Bikes</Link>
          <Link to="/boats">Boats</Link>
          <Link to="/trucks">Trucks</Link>
          <Link to="/agriculture">Agriculture</Link>
          <Link to="/earth-moving">Earth Moving</Link>
          <Link to="/new">New</Link>
          <Link to="/used">Used</Link>
          <Link to="/car-rentals">Car Rentals</Link>
          <Link to="/dealers">Dealers</Link>
        </div>
        <div className="text-zinc-300">
          <div>Harare Zimbabwe</div>
          <br />
          <div>We are availale 24/7.</div>
          <div className="text-zinc-600">All days</div>
        </div>

        <div className="border-solid border border-white flex flex-col gap-8 justify-between p-8 rounded-3xl">
          <div className="text-3xl">Subscribe to our newsletter</div>
          <div>
            <div className="group flex py-1">
              <input
                type="email"
                placeholder="your@email.ru"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="peer bg-transparent w-full py-1 focus:outline-none text-white border-b-4 border-solid border-zinc-600 focus:border-zinc-400"
              />
              <button
                onClick={handleSubscribe}
                className="text-yellow-600 border-b-4 border-solid border-zinc-600 peer-focus:border-zinc-400"
              >
                &#10140;
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between text-xs mt-16 sm:text-sm">
        <div>Copyright@2024</div>
        <div>Privacy</div>
      </div>
    </div>
  );
};

export default Footer;
