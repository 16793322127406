import { Link } from "react-router-dom";
import DealersCard from "./DealersCard";

function DealersList({ dealers }) {
  console.log(dealers);
  return (
    <>
      {/* eg.browse used dealers */}
      <div className="container mx-auto my-16 px-4">
        <h4 className="font-bold text-3xl my-4">Browse Dealers</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8">
          {dealers?.map((item) => (
            <Link to={`/dealers/${item._id}`}>
              <DealersCard item={item} key={item.name} />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default DealersList;
