import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Signup() {
  const navigate = useNavigate();

  const [userType, setUserType] = useState("seller");
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    username: "",
    governmentID: [""],
    category: "",
    location: "",
    logo: [""],
    password: "",
    confirmPassword: "",
    role: "dealership",
    dealershipName: "",
    description: "",
  });

  const [buyerData, setBuyerData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    role: "buyer",
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setData((prevData) => ({
        ...prevData,
        [name]: Array.from(files),
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (localStorage.status === "buyer") {
      setUserType("buyer");
    }
  }, []);

  const handleBuyerInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setBuyerData((prevData) => ({
        ...prevData,
        [name]: Array.from(files),
      }));
    } else {
      setBuyerData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic form validation
    if (data.password !== data.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Prepare form data for API submission
    const formData = new FormData();
   for (const key in data) {
        if (Array.isArray(data[key])) {
            data[key].forEach((file) => {
                formData.append(key, file);
            });
        } else {
            formData.append(key, data[key]);
        }
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/auth/signup`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          localStorage.setItem("token", result.token);
          localStorage.setItem("user", JSON.stringify(result.user));
          alert("Signup successful!");
          console.log("data", data);
          if (data.category === "individual") {
            navigate("/dashboard");
          } else {
            navigate("/subscriptions");
          }
          // Optionally redirect user or clear form
          // e.g., navigate("/dashboard");
        } else {
          alert(result.message || "Signup failed!");
        }
      })
      .catch((error) => {
        console.log(error);
        console.error("Error during signup:", error);
        alert(error);
      });
  };

  const handleBuyerSubmit = (e) => {
    e.preventDefault();

    // Basic form validation
    if (buyerData.password !== buyerData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Prepare form data for API submission
    const formData = new FormData();
    for (const key in buyerData) {
      formData.append(key, buyerData[key]);
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/auth/signup`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          localStorage.setItem("token", result.token);
          localStorage.setItem("buyer", JSON.stringify(result.user.role));
          localStorage.setItem("user", JSON.stringify(result.user));
          alert("Signup successful!");
          console.log("data", data);
          window.history.back();
          // Optionally redirect user or clear form
          // e.g., navigate("/dashboard");
        } else {
          alert(result.message || "Signup failed!");
        }
      })
      .catch((error) => {
        let errorMsg = error.json();
        console.error("Error during signup:", errorMsg);
        alert(error);
      });
  };

  useEffect(() => {
    if (localStorage.user) {
      let userRegistration = JSON.parse(localStorage.user);
      if (
        localStorage.token &&
        userRegistration.role == "dealership" &&
        userRegistration.subscriptionType !== ""
      ) {
        navigate("/subscriptions");
      }
    }
  });

  return (
    <div className="py-32 px-4">
      <div className="flex flex-col items-center gap-4 mb-4">
        <h2 className="font-bold text-4xl">Signup As</h2>
        <div className="flex gap-3">
          <button
            onClick={() => setUserType("seller")}
            className={`${
              userType === "seller"
                ? "text-black border-black"
                : "text-zinc-400 border-white"
            } text-xl py-1 border-b-2 border-solid font-semibold hover:text-zinc-700`}
          >
            Dealership
          </button>
          <button
            onClick={() => setUserType("buyer")}
            className={`${
              userType === "buyer"
                ? "text-black border-black"
                : "text-zinc-400 border-white"
            } text-xl py-1 border-b-2 border-solid font-semibold hover:text-zinc-700`}
          >
            Individual
          </button>
        </div>
      </div>
      {userType === "seller" ? (
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto space-y-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="name" className="font-semibold text-xl pl-1">
              Your Name <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={data.name}
              onChange={handleInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              minLength="4"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="font-semibold text-xl pl-1">
              Email <span className="text-red-600">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={data.email}
              onChange={handleInputChange}
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="phoneNumber" className="font-semibold text-xl pl-1">
              Phone Number <span className="text-red-600">*</span>
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={data.phoneNumber}
              onChange={handleInputChange}
              pattern="[0-9]{4}-[0-9]{7}"
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="username" className="font-semibold text-xl pl-1">
              Username <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={data.username}
              onChange={handleInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              minLength="4"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="governmentID"
              className="font-semibold text-lg pl-1"
            >
              Upload Government ID&nbsp;
              <span className="text-sm text-zinc-400">(optional)</span>
            </label>
            <input
              type="file"
              id="governmentID"
              name="governmentID"
              // accept="image/png, image/pdf"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="category" className="font-semibold text-xl pl-1">
              Category <span className="text-red-600">*</span>
            </label>
            <select
              name="category"
              id="category"
              value={data.category}
              onChange={handleInputChange}
              className="border border-solid border-zinc-400 rounded-lg p-2"
            >
              <option value="">--Please choose an option--</option>
              <option value="dealership">Dealership</option>
              <option value="individual">Individual</option>
            </select>
          </div>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="dealershipName"
              className="font-semibold text-xl pl-1"
            >
              Dealer Name <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              id="dealershipName"
              name="dealershipName"
              value={data.dealershipName}
              onChange={handleInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              minLength="4"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="location" className="font-semibold text-xl pl-1">
              Location <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={data.location}
              onChange={handleInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              minLength="4"
              maxLength="32"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="description" className="font-semibold text-xl pl-1">
              Description <span className="text-red-600">*</span>
            </label>
            <textarea
              id="description"
              name="description"
              rows="5"
              value={data.description}
              onChange={handleInputChange}
              className="border border-solid border-zinc-400 rounded-lg p-2"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="logo" className="font-semibold text-lg pl-1">
              Add Logo&nbsp;
              <span className="text-sm text-zinc-400">(optional)</span>
            </label>
            <input
              type="file"
              id="logo"
              name="logo"
              accept="image/png, image/jpeg"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="font-semibold text-xl pl-1">
              Password <span className="text-red-600">*</span>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={data.password}
              onChange={handleInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              placeholder="At least 8 characters"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="confirmPassword"
              className="font-semibold text-xl pl-1"
            >
              Confirm Password <span className="text-red-600">*</span>
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={data.confirmPassword}
              onChange={handleInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              placeholder="At least 8 characters"
            />
          </div>

          <button
            type="submit"
            className="mt-4 bg-[rgb(185,142,53)] text-white rounded-md py-2 px-6 font-semibold"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      ) : (
        <form
          onSubmit={handleBuyerSubmit}
          className="max-w-xl mx-auto space-y-4"
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="name" className="font-semibold text-xl pl-1">
              Your Name <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={buyerData.name}
              onChange={handleBuyerInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              minLength="4"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="font-semibold text-xl pl-1">
              Email <span className="text-red-600">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={buyerData.email}
              onChange={handleBuyerInputChange}
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="phoneNumber" className="font-semibold text-xl pl-1">
              Phone Number <span className="text-red-600">*</span>
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={buyerData.phoneNumber}
              onChange={handleBuyerInputChange}
              pattern="[0-9]{4}-[0-9]{7}"
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="font-semibold text-xl pl-1">
              Password <span className="text-red-600">*</span>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={buyerData.password}
              onChange={handleBuyerInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              placeholder="At least 8 characters"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="confirmPassword"
              className="font-semibold text-xl pl-1"
            >
              Confirm Password <span className="text-red-600">*</span>
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={buyerData.confirmPassword}
              onChange={handleBuyerInputChange}
              required
              className="border border-solid border-zinc-400 rounded-lg p-2"
              placeholder="At least 8 characters"
            />
          </div>

          <button
            type="submit"
            className="mt-4 bg-[rgb(185,142,53)] text-white rounded-md py-2 px-6 font-semibold"
            onClick={handleBuyerSubmit}
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
}

export default Signup;
