import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import SliderImg1 from "../../Images/sliderImg1.png";
import SliderImg2 from "../../Images/sliderImg2.png";
import SliderImg3 from "../../Images/sliderImg3.png";
import styles from "./VehiclePage.module.css";
import Slider from "react-slick";

const Feedback = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false, // Hides the arrows
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles.feedbackMain}>
      <div className={styles.feedbackHeading}>Our Customers Feedback</div>

      <div className={styles.feedbackSliderMain}>
        <Slider {...settings}>
          <div>
            <div className={styles.sliderContainer}>
              <div className={styles.imgRateMain}>
                <img src={SliderImg1} alt="" className={styles.sliderimg} />
                <div>
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={5}
                      precision={1}
                      readOnly
                    />
                  </Stack>
                </div>
              </div>
              {/* <div className={styles.SliderHeading}>Floyd Miles</div> */}
              <div className={styles.SliderText}>
                I'm excited about the potential of BuySellCars.co.zw! Listing my
                car was a breeze, and the site is incredibly user-friendly.
                Although the platform is new, I'm already seeing interest from
                potential buyers. The premium features make my listing stand
                out, and I can't wait to see how this site continues to grow and
                connect sellers like me with eager buyers across Zimbabwe!
              </div>
            </div>
          </div>
          <div>
            <div className={styles.sliderContainer}>
              <div className={styles.imgRateMain}>
                <img src={SliderImg2} alt="" className={styles.sliderimg} />
                <div>
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={5}
                      precision={1}
                      readOnly
                    />
                  </Stack>
                </div>
              </div>
              {/* <div className={styles.SliderHeading}>Ronald Richards</div> */}
              <div className={styles.SliderText}>
                BuySellCars.co.zw is a breath of fresh air for car sellers in
                Zimbabwe. The intuitive design and premium features are
                impressive, and even as a brand-new site, it's already
                generating interest in my listings. I'm confident that this
                platform will become the go-to for anyone looking to sell
                vehicles in Zimbabwe. It's exciting to be part of this journey
                from the start!
              </div>
            </div>
          </div>
          <div>
            <div className={styles.sliderContainer}>
              <div className={styles.imgRateMain}>
                <img src={SliderImg3} alt="" className={styles.sliderimg} />
                <div>
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={5}
                      precision={1}
                      readOnly
                    />
                  </Stack>
                </div>
              </div>
              {/* <div className={styles.SliderHeading}>Savannah Nguyen</div> */}
              <div className={styles.SliderText}>
                As a dealership, we're thrilled to be among the first to
                experience BuySellCars.co.zw. The platform's seamless
                integration and premium offerings are setting the stage for
                increased visibility and future sales leads. While the site is
                just getting started, we're optimistic about the potential it
                holds for transforming the automotive market in Zimbabwe. This
                is the innovative solution we've been waiting for!
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};
export default Feedback;
