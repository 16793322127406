import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./VehiclePage.module.css";
import DealersList from "../../Components/Common/DealersList";
import { Audio } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import DealerVehicleList from "../../Components/Common/DealerVehicleList";

const DealersDetailPage = ({ bg }) => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { dealerId } = useParams();

  useEffect(() => {
    fetchDealerListings();
  }, []);

  const fetchDealerListings = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-listing-by-dealer/${dealerId}`
      );
      setVehicles(response?.data?.data);
      console.log("lalalal", response?.data?.data);
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <div className="flex items-center h-screen justify-center">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="rgb(185, 142, 53)"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : (
    <div>
      <div className={styles.carsMain}>
        {/* Vehicle List will generate the list of cars or boats or trucks based on data provided as prop */}
        {<DealerVehicleList vehicles={vehicles} />}
      </div>
    </div>
  );
};

export default DealersDetailPage;
