import { Link } from "react-router-dom";
import VehicleCard from "./VehicleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules"; // Import Autoplay module
import "swiper/css";
import React, { useEffect, useState } from "react";

function DealerVehicleList({ featuredVehicles, vehicles }) {
  // Pagination states
  const [currentVehiclesPage, setCurrentVehiclesPage] = useState(1);

  const vehiclesItemsPerPage = 12;

  // Calculate ranges for vehicles
  const vehiclesIndexOfLastItem = currentVehiclesPage * vehiclesItemsPerPage;
  const vehiclesIndexOfFirstItem =
    vehiclesIndexOfLastItem - vehiclesItemsPerPage;
  const currentVehiclesItems = vehicles?.slice(
    vehiclesIndexOfFirstItem,
    vehiclesIndexOfLastItem
  );
  const totalVehiclesPages = Math.ceil(vehicles?.length / vehiclesItemsPerPage);

  return (
    <>
      {/* Browse Vehicles */}
      <div className="max-w-[1400px] mx-auto my-16 px-4">
        <h4 className="font-bold text-3xl my-4">Browse</h4>
        <div className="grid gap-x-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {currentVehiclesItems.map((item) => (
            <SwiperSlide key={item._id}>
              <Link
                to={`/cars/${item._id}`}
                onClick={() => localStorage.setItem("carId", item._id)}
              >
                <VehicleCard item={item} featured={false} />
              </Link>
            </SwiperSlide>
          ))}
        </div>
        {/* Pagination Controls for Vehicles */}
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mx-2 bg-gray-300 rounded"
            disabled={currentVehiclesPage === 1}
            onClick={() => setCurrentVehiclesPage(currentVehiclesPage - 1)}
          >
            Previous
          </button>
          <span className="content-center">
            Page {currentVehiclesPage} of {totalVehiclesPages}
          </span>
          <button
            className="px-4 py-2 mx-2 bg-gray-300 rounded"
            disabled={currentVehiclesPage === totalVehiclesPages}
            onClick={() => setCurrentVehiclesPage(currentVehiclesPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default DealerVehicleList;
