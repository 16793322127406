import { useState, useEffect } from "react";
import DashboardAddListing from "../../Components/DashboardAddListing";
import DashboardListings from "../../Components/DashboardListings";
import DashboardFeature from "../../Components/DashboardFeature";
import DashboardPerformance from "../../Components/DashboardPerformance";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

function DashBoard() {
  const navigate = useNavigate();
  const [tab, setTab] = useState("listings");
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));

      if (!token) {
        navigate("/login");
        return;
      }

      if (user?.category === "dealership" && !user?.subscriptionType) {
        navigate("/subscriptions");
        return;
      }
    };

    const fetchListings = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/listing/get-all-listings-logged-in`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );

        const listings = response?.data?.data || [];
        setVehicles(listings);

        if (listings.length === 0) {
          setTab("new-listing");
        }

        const userLoggedIn = response?.data?.userLoggedIn?.[0];
        if (userLoggedIn?.subscriptionType === "") {
          navigate("/subscriptions");
        }
      } catch (err) {
        setError("Failed to fetch listings.");
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();

    if (tab === "listings") {
      fetchListings();
    }
  }, [tab, navigate]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const renderContent = () => {
    switch (tab) {
      case "new-listing":
        return <DashboardAddListing vehicles={vehicles} />;
      case "listings":
        return <DashboardListings vehicles={vehicles} />;
      case "feature":
        return <DashboardFeature vehicles={vehicles} />;
      case "performance":
        return <DashboardPerformance vehicles={vehicles} />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Audio
          height="80"
          width="80"
          radius="9"
          color="rgb(185, 142, 53)"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <div className="md:grid md:grid-cols-[1fr_2fr] xl:grid-cols-[1fr_4fr]">
      <div className="bg-[#F5F5F5C7] md:flex md:min-h-[80dvh] md:m-6 justify-center px-4 py-16">
        <div className="flex flex-col items-start gap-12">
          {[
            { id: "listings", label: "Your Listing", icon: "M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zm0 160c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24H40zM64 368c0-17.7-14.3-32-32-32s-32 14.3-32 32v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24H64z" },
            { id: "new-listing", label: "Add new listing", icon: "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32v144H48c-17.7 0-32 14.3-32 32s14.3 32 32 32h144v144c0 17.7 14.3 32 32 32s32-14.3 32-32V256h144c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" },
            { id: "feature", label: "Feature", icon: "M352 320c88.4 0 160-71.6 160-160 0-15.3-2.2-30.1-6.2-44.2-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16v-57.4c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0 263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1c0 37.5 30.4 67.9 67.9 67.9 18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" },
            { id: "performance", label: "Performance", icon: "M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64v336c0 44.2 35.8 80 80 80h400c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" },
          ].map(({ id, label, icon }) => (
            <button
              key={id}
              className="flex items-center gap-3"
              onClick={() => setTab(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`$${tab === id ? "fill-[#E7B04C]" : "fill-zinc-700"} h-6 w-6`}
                viewBox="0 0 512 512"
              >
                <path d={icon} />
              </svg>
              <span className={`${tab === id ? "text-[#E7B04C]" : "text-zinc-700"}`}>{label}</span>
            </button>
          ))}
          <button
            className="text-red-700 font-bold"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="p-6">{renderContent()}</div>
    </div>
  );
}

export default DashBoard;