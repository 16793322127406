import React, { useState } from "react";
import styles from "./VehiclePage.module.css";

const Search = ({ onSearch }) => {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  const [searchInputs, setSearchInputs] = useState({
    make: "",
    model: "",
    year: "",
    condition: "",
    type: "",
    priceFrom: "",
    priceTo: "",
    location: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    onSearch(searchInputs);
  };

  return (
    <div className={styles.searchMain}>
      <div className={styles.searchContainer}>
        <div className={styles.select1Main}>
          {/* Make Input */}
          <input
            placeholder="Make"
            name="make"
            className={styles.searchSelect}
            onChange={handleChange}
          />

          {/* Model Input */}
          <input
            placeholder="Model"
            name="model"
            className={`${styles.searchSelect} max-md:mt-4`}
            onChange={handleChange}
          />

          {/* Year Dropdown */}
          <select
            name="year"
            className={`${styles.searchSelect} max-lg:mt-4`}
            onChange={handleChange}
          >
            <option value="">Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <br />

        <div className={styles.select1Main}>
          {/* Condition Input */}
          <input
            placeholder="Condition"
            name="condition"
            className={styles.searchSelect}
            onChange={handleChange}
          />

          {/* Type Input */}
          <input
            placeholder="Type"
            name="type"
            className={`${styles.searchSelect} max-md:mt-4`}
            onChange={handleChange}
          />

          <input
            placeholder="Location"
            name="location"
            className={`${styles.searchSelect} max-md:mt-4`}
            onChange={handleChange}
          />

          {/* Price Range Inputs */}
          <input
            type="number"
            placeholder="Price From"
            name="priceFrom"
            className={`${styles.searchSelect} max-md:mt-4 md:mt-4`}
            onChange={handleChange}
          />

          <input
            type="number"
            placeholder="Price To"
            name="priceTo"
            className={`${styles.searchSelect} max-md:mt-4 md:mt-4`}
            onChange={handleChange}
          />
        </div>
        <br />

        {/* Search Button */}
        <div className={styles.searchBtnMain}>
          <button className={styles.searchBtn} onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default Search;
