function VehicleCard({ item }) {
  console.log(item?.featured);
  return (
    <div className="xl:max-w-96 shadow-xl shadow-zinc-100 h-full rounded-3xl">
      <figure className="relative">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${item.pictures[0]}`}
          alt=""
          className="h-64 w-full object-cover rounded-t-3xl"
        />
        <div>
          <span className="absolute top-[50%] left-[20%] md:left-[30%] text-white p-1 rounded-tr-sm">
            {item?.user?.dealershipName}
          </span>
        </div>
        {item?.featured && (
          <span className="absolute bottom-0 left-0 text-white bg-[#E7B04C] p-1 rounded-tr-sm">
            FEATURED
          </span>
        )}
      </figure>
      <div className="p-4 mb-4 space-y-1 sm:space-y-2">
        <p className="font-semibold text-xl sm:text-xl md:text-2xl lg:text-3xl md:h-[72px]">
          {`${item?.make} ${item?.model} ${item?.year}`}
        </p>
        <p className="text-[#E7B04C] text-lg font-semibold">${item.price}</p>
        <p className="text-xs sm:text-base">{item.condition}</p>
        <p className="text-xs sm:text-base">{item.location}</p>
      </div>
    </div>
  );
}

export default VehicleCard;
